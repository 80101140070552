import { BoilerBrand } from '@entities/enums';

export interface BoilerBrandDetails {
  id: BoilerBrand;
  name: string;
  maxAge: number;
}

export const MAX_BOILER_AGE = 18;

/* The age at which we apply a premium to the insurance price */
export const OLDER_BOILER_AGE = 7;

/* The amount we apply to the insurance price */
export const OLDER_BOILER_PREMIUM_AMOUNT = 2;

export const boilerBrands: BoilerBrandDetails[] = [
  {
    id: BoilerBrand.ALPHA,
    name: 'Alpha',
    maxAge: MAX_BOILER_AGE,
  },
  {
    id: BoilerBrand.ARISTON,
    name: 'Ariston',
    maxAge: MAX_BOILER_AGE,
  },
  {
    id: BoilerBrand.ATAG,
    name: 'ATAG',
    maxAge: MAX_BOILER_AGE,
  },
  {
    id: BoilerBrand.BAXI_POTTERTON,
    name: 'Baxi / Potterton',
    maxAge: MAX_BOILER_AGE,
  },
  {
    id: BoilerBrand.BG,
    name: 'British Gas',
    maxAge: MAX_BOILER_AGE,
  },
  {
    id: BoilerBrand.GLOWWORM,
    name: 'Glow Worm',
    maxAge: MAX_BOILER_AGE,
  },
  {
    id: BoilerBrand.IDEAL,
    name: 'Ideal',
    maxAge: MAX_BOILER_AGE,
  },
  {
    id: BoilerBrand.INTERGAS,
    name: 'Intergas',
    maxAge: MAX_BOILER_AGE,
  },
  {
    id: BoilerBrand.MAIN,
    name: 'Main',
    maxAge: MAX_BOILER_AGE,
  },
  {
    id: BoilerBrand.PRO,
    name: 'Pro Combi',
    maxAge: MAX_BOILER_AGE,
  },
  {
    id: BoilerBrand.REMEHA,
    name: 'Remeha',
    maxAge: MAX_BOILER_AGE,
  },
  {
    id: BoilerBrand.VAILLANT,
    name: 'Vaillant',
    maxAge: MAX_BOILER_AGE,
  },
  {
    id: BoilerBrand.VIESSMANN,
    name: 'Viessmann',
    maxAge: MAX_BOILER_AGE,
  },
  {
    id: BoilerBrand.VOKERA,
    name: 'Vokera',
    maxAge: MAX_BOILER_AGE,
  },
  {
    id: BoilerBrand.WORCESTER,
    name: 'Worcester',
    maxAge: MAX_BOILER_AGE,
  },
  {
    id: BoilerBrand.OTHER,
    name: 'Other',
    maxAge: 0,
  },
];

export default boilerBrands;
