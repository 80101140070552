import jwtDecode from 'jwt-decode';
import { QueryClient } from 'react-query';

import HttpService from '@services/HttpService';
import { AccountId } from '@entities/types';
import * as Logger from '@src/logging/logger';

const ACCOUNT_MATCH_PATTERN = /orion-exp::account::([^:]+)/;

const LEEWAY_SECONDS = 10;
export type CustomerToken = {
  accessToken: {
    value: string;
  };
  accessExpiry: number;
  refreshExpiry: number;
};

export type DecodedToken = {
  sub: string;
  exp: number;
  email: string;
  family_name: string;
  given_name: string;
  permissions: string[];
};

export type TokenResponse = {
  accessToken: {
    value: string;
  };
  expiresIn: number;
  refreshExpiresIn: number;
};

export type Customer = {
  accountId: AccountId;
  customerId?: string;
  forename?: string;
  surname?: string;
  email?: string;
};

class AuthService {
  private static instance: AuthService;
  private token?: CustomerToken | null;
  private queryClient: QueryClient;

  constructor() {
    this.queryClient = new QueryClient();
  }

  public static getInstance(): AuthService {
    if (!this.instance) {
      this.instance = new AuthService();
    }
    return this.instance;
  }

  public reset = () => {
    this.token = null;
  };

  logOut = () => {
    this.token = null;
  };

  getAccessToken = async (): Promise<string> => {
    const token = await this.requestToken();
    return token.accessToken.value;
  };

  getCurrentlyLoggedInUser = async (): Promise<Customer | null> => {
    try {
      const token = await this.requestToken();

      if (!token || !token.accessToken) {
        Logger.error('No token found');
        return null;
      }

      const decoded: DecodedToken = jwtDecode(token.accessToken.value);
      const permission = decoded.permissions.find((p) => ACCOUNT_MATCH_PATTERN.test(p));

      const accountId = permission?.match(ACCOUNT_MATCH_PATTERN)?.[1];
      if (!accountId) {
        Logger.error('Failed to fetch accountId from permissions', {
          permissions: decoded.permissions,
        });
        return Promise.reject(`No account id found`);
      }
      Logger.setGlobalProperty('accountId', accountId);
      Logger.setUser({ id: decoded.sub, email: decoded.email });
      return {
        accountId,
        customerId: decoded.sub,
        email: decoded.email,
        forename: decoded.given_name,
        surname: decoded.family_name,
      };
    } catch (error) {
      Logger.error('Failed to get currently Logged in user', {
        error: JSON.stringify(error),
      });
      return null;
    }
  };

  private requestToken = async (): Promise<CustomerToken> => {
    if (!process.env.GATSBY_MY_OVO_URL) {
      const error = 'TOKEN_URL is not defined';
      return Promise.reject(error);
    }

    // if refresh token expired, log out and don't request an access token
    if (this.token?.refreshExpiry && new Date().getTime() >= this.token.refreshExpiry) {
      this.token = null;
      this.handleLogout();

      // shouldn't ever get here, but logout redirect may take some time
      return Promise.reject('Refresh expired');
    }

    if (this.token?.accessExpiry && new Date().getTime() < this.token.accessExpiry) {
      // return cached token
      return this.token;
    }
    try {
      Logger.debug('Requesting new token');
      const token = await HttpService.get<TokenResponse>({
        endpoint: process.env.GATSBY_MY_OVO_URL + '/api/v2/auth/token',
        useCredentials: true,
      });
      Logger.debug('Received new token');
      this.token = this.buildCustomerToken(token);
      return this.token;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  private buildCustomerToken = (tokenResponse: TokenResponse): CustomerToken => {
    // cache token and expiry
    const decoded: DecodedToken = jwtDecode(tokenResponse.accessToken.value);
    return {
      accessToken: { value: tokenResponse.accessToken.value },
      // convert to millis
      accessExpiry: (Number(decoded.exp) - LEEWAY_SECONDS) * 1000,
      refreshExpiry: new Date().getTime() + tokenResponse.refreshExpiresIn * 1000,
    };
  };

  private handleLogout = () => {
    Logger.debug('Logging out due to expired refresh token, Redirecting to portal');
    const search = new URLSearchParams(location.search).toString();
    const params = encodeURIComponent(`&selectedPage=${location.pathname}&${search}`);

    window.location.replace(process.env.GATSBY_PORTAL_URL + params);
  };
}

export default AuthService.getInstance();
