import styled, { css } from 'styled-components';
import { TextLink } from '@ovotech/element';

import { MixpanelEvents } from '@entities/enums';
import { useCustomMixpanel } from '@services/Mixpanel';
import { getEnergyCreditTermsURL } from '@utils/EnergyCredit';

export const RewardTermsAndConditionsLink = styled(TextLink)(
  ({ theme }) => css`
    font-size: ${theme.fontSizes[1]};
    text-align: center;
  `
);
export const RewardOfferTermsAndConditions = () => {
  const { track } = useCustomMixpanel();

  return (
    <RewardTermsAndConditionsLink
      href={getEnergyCreditTermsURL()}
      target="_blank"
      opensInNewWindow
      rel="noopener noreferrer"
      aria-label="Reward offer terms and conditions"
      onClick={() =>
        track(MixpanelEvents.BUTTON_CLICK, {
          buttonId: 'reward-offer-terms-and-conditions',
        })
      }
    >
      reward offer terms and conditions
    </RewardTermsAndConditionsLink>
  );
};
