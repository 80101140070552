import StoreService from '@services/StoreService';
import { BOILER_COVER_REWARD_TERMS_FIXED } from '@constants/OVOUrls';
import boilerCoverRewardTerms from '@resources/energy-boiler-cover-reward-terms.pdf';

export const getEnergyCreditTermsURL = () => {
  const { creditType } = StoreService.load();

  if (creditType === 'FIXED') {
    return BOILER_COVER_REWARD_TERMS_FIXED;
  }

  return boilerCoverRewardTerms;
};

export const getEnergyCreditEligiblePlan = () => {
  const { creditType } = StoreService.load();

  if (creditType === 'FIXED') {
    return 'fixed plans';
  }

  return 'variable plan';
};
