/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import 'url-search-params-polyfill';

import React from 'react';
import { LocationProvider } from '@reach/router';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from '@ovotech/element';

import { LocalStorageProvider } from './src/contexts/LocalStorageContext';
import { AppContextProvider } from './src/contexts/AppContext';
import { OptimizelyProvider } from './src/services/optimizely';
import { FootnotesProvider } from './src/components/Footer/Footnotes';
import { MixpanelProvider } from './src/services/Mixpanel';
import { AppThemeProvider } from './src/theme';
import { DevOverlay } from './src/components/DevOverlay/DevOverlay';
import * as Logger from './src/logging/logger';
import { Qualtrics } from './src/services/Qualtrics';

const queryClient = new QueryClient();
Logger.init();

export const wrapRootElement = ({ element }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <LocationProvider>
        {({ location }) => (
          <LocalStorageProvider>
            <AppContextProvider location={location}>
              <OptimizelyProvider>
                <AppThemeProvider>
                  <FootnotesProvider>
                    <MixpanelProvider>
                      <Qualtrics>
                        <ToastContainer position={'top-right'} />
                        <DevOverlay />
                        {element}
                      </Qualtrics>
                    </MixpanelProvider>
                  </FootnotesProvider>
                </AppThemeProvider>
              </OptimizelyProvider>
            </AppContextProvider>
          </LocalStorageProvider>
        )}
      </LocationProvider>
    </QueryClientProvider>
  );
};

export const onClientEntry = () => {
  // Inject mocks.
  if (process.env.NODE_ENV === 'development') {
    const { extractScenarioFromLocation, injectMocks } = require('data-mocks');
    const { getMocks } = require('./src/mocks');

    injectMocks(getMocks(), extractScenarioFromLocation(window.location), {
      allowXHRPassthrough: true,
      allowFetchPassthrough: true, // required for hot-reloading
    });

    // Ensures that our custom styles (styled-components) take precedence over the @ovotech/element library styles
    if (module.hot) {
      module.hot.accept();
      const moveStyledToEnd = () => {
        const head = document.head;
        const styledComponents = head.querySelectorAll('style[data-styled="active"]');
        styledComponents.forEach((styleTag) => {
          head.appendChild(styleTag);
        });
      };

      moveStyledToEnd();
      module.hot.addStatusHandler((status) => {
        if (status === 'idle') {
          moveStyledToEnd();
        }
      });
    }
  }
};
