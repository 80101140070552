import { directPromos } from './direct';
import { indirectPromos } from './indirect';
import { autoTestDirectPromos, autoTestIndirectPromos } from './autoTest';

import { PromoConfig } from '@entities/types';

const isProduction = process.env.GATSBY_ACTIVE_ENV === 'production';

const allPromos: PromoConfig[] = [
  ...indirectPromos,
  ...directPromos,
  ...(isProduction ? [] : autoTestIndirectPromos),
  ...(isProduction ? [] : autoTestDirectPromos),
];

export const getIndirectPromos = () => [
  ...indirectPromos,
  ...(isProduction ? [] : autoTestIndirectPromos),
];
export const getDirectPromos = () => [
  ...directPromos,
  ...(isProduction ? [] : autoTestDirectPromos),
];
export const getAllPromos = () => [...allPromos];
