export const DONT_NEED_COVER = 'https://ondemand.ovoenergy.com/repair/heating';

export const BOILER_COVER_REWARD_TERMS =
  'https://www.ovoenergy.com/terms/energy-boiler-cover-reward';

export const BOILER_COVER_REWARD_TERMS_FIXED =
  'https://www.ovoenergy.com/fixed-energy-and-boiler-cover-reward-terms';

export const HOME_ENERGY_PLANS = 'https://www.ovoenergy.com/home-energy-plans';

export const PRIVACY_POLICY =
  'https://www.ovobyus.com/m/128d9ac844ed663c/original/sr-ref-003-ohp-privacy-policy.pdf';
