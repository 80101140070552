export const getCurrentDate = () => {
  const env = process.env.GATSBY_DD_ENV;
  if (env === 'prod') {
    return new Date();
  }

  const sessionDate = sessionStorage.getItem('mock-date');

  if (sessionDate) {
    return new Date(sessionDate);
  }

  return new Date();
};
