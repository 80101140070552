import { q3FreeMonthsInsuranceFootnotes, q2HalfPriceAbsFootnotes } from '@constants/footnotes';
import { ProductName } from '@entities/enums';
import { PromoConfig, PromoName } from '@entities/types';
import StoreService from '@services/StoreService';

export const indirectPromos: PromoConfig[] = [
  {
    order: 2,
    name: PromoName.HeadlineOfferThreeMonthsFree,
    appliesTo: [ProductName.STARTER, ProductName.ESSENTIALS, ProductName.COMPLETE],
    startDate: new Date('2024-09-17T00:00Z'),
    endDate: new Date('2024-12-12T23:59:59'),
    footnote: q3FreeMonthsInsuranceFootnotes.q3FreeMonthsInsurance,
    promoOptions: {
      type: 'FIRSTMONTHSFREE',
      duration: 3,
    },
    validate: () => {
      const { isBeyond } = StoreService.load();

      return !isBeyond;
    },
  },
  {
    order: 5,
    name: PromoName.HomeRecoverFreeRepair,
    appliesTo: [ProductName.COMPLETE],
    addon: true,
    startDate: new Date('2024-09-17T00:00Z'),
    endDate: new Date('2024-12-12T23:59:59'),
    promoOptions: {
      type: 'FREEREPAIR',
    },
    validate: () => {
      const store = StoreService.load();
      return store.isHomeRecover === true && store.tariffName === undefined;
    },
  },
  {
    order: 1,
    name: PromoName.FlashSaleSixMonthsFree,
    appliesTo: [ProductName.STARTER, ProductName.ESSENTIALS, ProductName.COMPLETE],
    startDate: new Date('2024-10-01T00:00Z'),
    endDate: new Date('2024-10-17T23:59:00'),
    footnote: q3FreeMonthsInsuranceFootnotes.q3FreeMonthsInsuranceFlashSale,
    promoOptions: {
      type: 'FIRSTMONTHSFREE',
      duration: 6,
    },
    validate: () => {
      const { isBeyond } = StoreService.load();

      return !isBeyond;
    },
  },
  {
    order: 1,
    name: PromoName.BlackFridaySixMonthsFree,
    footnote: q3FreeMonthsInsuranceFootnotes.q3FreeMonthsInsuranceBlackFriday,
    appliesTo: [ProductName.STARTER, ProductName.ESSENTIALS, ProductName.COMPLETE],
    startDate: new Date('2024-11-19T00:00Z'),
    endDate: new Date('2024-12-05T23:59:00'),
    promoOptions: {
      type: 'FIRSTMONTHSFREE',
      duration: 6,
    },
    validate: () => {
      const { isBeyond } = StoreService.load();

      return !isBeyond;
    },
  },
  {
    order: 3,
    validate: () => {
      const store = StoreService.load();
      return Boolean(
        !store.isHomeRecover &&
          !store.isBeyond &&
          store.isCreditEligible &&
          store.creditType === 'SVT'
      );
    },
    name: PromoName.EnergyCreditSVT,
    addon: true,
    appliesTo: [ProductName.STARTER, ProductName.ESSENTIALS, ProductName.COMPLETE],
    endDate: new Date('2024-09-17T00:00Z'),
    promoOptions: {
      type: 'ENERGYCREDIT',
      amounts: {
        [ProductName.ESSENTIALS]: 50,
        [ProductName.STARTER]: 50,
        [ProductName.COMPLETE]: 50,
      },
    },
  },
  {
    order: 3,
    validate: () => {
      const store = StoreService.load();
      return Boolean(
        !store.isHomeRecover &&
          !store.isBeyond &&
          store.isCreditEligible &&
          store.creditType === 'FIXED'
      );
    },
    startDate: new Date('2024-02-28T09:00Z'),
    endDate: new Date('2024-09-17T00:00Z'),
    name: PromoName.EnergyCreditFixed,
    addon: true,
    appliesTo: [ProductName.STARTER, ProductName.ESSENTIALS, ProductName.COMPLETE],
    promoOptions: {
      type: 'ENERGYCREDIT',
      amounts: {
        [ProductName.ESSENTIALS]: 100,
        [ProductName.STARTER]: 100,
        [ProductName.COMPLETE]: 100,
      },
    },
  },
  {
    order: 100,
    name: PromoName.Q2AbsOffer,
    startDate: new Date('2024-04-02T00:00Z'),
    endDate: new Date('2024-07-25T23:59:00.000+01:00'),

    appliesTo: [ProductName.ABS],
    footnote: q2HalfPriceAbsFootnotes?.q2HalfPriceAbs,
    promoOptions: {
      type: 'PERCENTDISCOUNT',
      percentage: 50,
    },
  },
];
