import { TrackingFields } from './CorgiService';

import { AutoRenewalOption, BoilerBrand, BoilerBrandExclusions, CoverType } from '@entities/enums';
import { AccountId } from '@entities/types';
import { Repair } from '@services/RepairService';
import { SignupDto } from '@src/generated/SignupDto';
import { getUrlParam } from '@utils/getUrlParam';

export interface PersonalDetails {
  title: SignupDto['customerDetails']['title'] | '';
  forename: string;
  surname: string;
  telephone: string;
  email: string;
  addressLine1: string;
  addressLine2?: string;
  postcode: string;
  town: string;
}

export interface BoilerDetails {
  isNotSupportedBoiler?: BoilerBrandExclusions;
  make: BoilerBrand | '';
  boilerAge: string;
  ownership: boolean;
  notGuesthouse: boolean;
}

export interface SensitivePaymentDetails {
  nameOnAccount: string;
  accountNumber: string;
  sortCode: string;
}

export interface PaymentDetails {
  accountOwner: boolean;
  termsAndConditions: boolean;
  rewardTermsAndConditions: boolean;
  isHomeRecover: boolean; // Required for correct validation of homeRecoverTerms
  homeRecoverTerms: boolean;
  paperless: boolean;
  autoRenewal: AutoRenewalOption;
}

export interface MarketingPermissions {
  email: boolean;
  sms: boolean;
  phone: boolean;
  post: boolean;
}

interface Tracking {
  source: string | null;
}

export interface Store {
  testUser: boolean;
  excess: number;
  accountId?: AccountId;
  selectedCoverType?: CoverType;
  orderReference?: string;
  signupComplete: boolean;
  personalDetails: PersonalDetails;
  boilerDetails: BoilerDetails;
  paymentDetails: PaymentDetails;
  marketingPermissions?: MarketingPermissions;
  sensitivePaymentDetails: SensitivePaymentDetails;
  promoCode: string;
  tracking: Tracking;
  utmTracking?: TrackingFields;
  isHomeRecover?: boolean;
  isBeyond?: boolean;
  isAbs?: boolean;
  isAgent?: boolean;
  repair?: Repair;
  isCreditEligible?: boolean;
  creditType?: 'SVT' | 'FIXED';
  tariffName?: string;
}

export const defaultMarketingPermissions = {
  email: false,
  sms: false,
  phone: true,
  post: true,
};

export const defaultStore: Store = {
  personalDetails: {
    title: '',
    forename: '',
    surname: '',
    telephone: '',
    email: '',
    addressLine1: '',
    addressLine2: '',
    postcode: '',
    town: '',
  },
  boilerDetails: {
    make: '',
    boilerAge: '',
    ownership: false,
    notGuesthouse: false,
    isNotSupportedBoiler: BoilerBrandExclusions.NOTEXCLUDED,
  },
  sensitivePaymentDetails: {
    nameOnAccount: '',
    accountNumber: '',
    sortCode: '',
  },
  paymentDetails: {
    accountOwner: false,
    termsAndConditions: false,
    rewardTermsAndConditions: false,
    isHomeRecover: false,
    homeRecoverTerms: false,
    paperless: true,
    autoRenewal: AutoRenewalOption.YES,
  },
  marketingPermissions: defaultMarketingPermissions,
  excess: 60,
  signupComplete: false,
  promoCode: '',
  tracking: {
    source: getUrlParam('source'),
  },
  isHomeRecover: false,
  isBeyond: false,
  isAbs: false,
  isAgent: false,
  isCreditEligible: false,
  testUser: false,
};

const sessionStorageKey = 'homePlan:store';

class StoreService {
  private store: Store;

  constructor() {
    this.store = {
      ...defaultStore,
      ...this.loadFromSessionStorage(),
    };
  }

  load(): Store {
    return this.store;
  }

  save(partial: Partial<Store>): void {
    this.store = {
      ...this.store,
      ...partial,
    };
    this.saveToSessionStorage();
  }

  removeKey(key: keyof Store): void {
    this.store = {
      ...this.store,
      [key]: undefined,
    };
    this.saveToSessionStorage();
  }

  clear(): void {
    sessionStorage.removeItem(sessionStorageKey);
    localStorage.removeItem(sessionStorageKey);
    this.store = defaultStore;
  }

  private loadFromSessionStorage(): Partial<Store> {
    return typeof window !== 'undefined' && window.sessionStorage.getItem(sessionStorageKey)
      ? (JSON.parse(sessionStorage.getItem(sessionStorageKey)!) as Partial<Store>)
      : {};
  }

  private saveToSessionStorage(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { sensitivePaymentDetails, ...filterSensitive } = this.store;
    typeof window !== 'undefined' &&
      window.sessionStorage.setItem(sessionStorageKey, JSON.stringify(filterSensitive));
  }
}

export default new StoreService();
