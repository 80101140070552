import React from 'react';
import { IconsProvider } from '@ovotech/element';

import { icons } from './index';

const IconProvider: React.FC = ({ children }) => {
  return <IconsProvider icons={icons}>{children}</IconsProvider>;
};

export default IconProvider;
