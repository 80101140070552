import { Icon, Modal } from '@ovotech/element';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import DevOverlayModalContent from './DevOverlayModalContent';

const OverlayButton = styled.div`
  position: fixed;
  bottom: 25px;
  right: 25px;
  border-radius: 50%;
  width: 48px;
  padding: 16px;
  height: 48px;
  text-align: center;
  background-color: ${({ theme }) => theme.core.color.green.lighter};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${({ theme }) => theme.core.color.green.lightest};
  }
`;

export const DevOverlay = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (process.env.GATSBY_DD_ENV === 'prod') {
    return null;
  }

  if (!isClient) {
    return null;
  }

  return (
    <div>
      <Modal isOpen={modalOpen} isDismissable={true} onClose={() => setModalOpen(false)}>
        <DevOverlayModalContent />
      </Modal>
      <OverlayButton onClick={() => setModalOpen(true)}>
        <Icon name="wrench" size={38} />
      </OverlayButton>
    </div>
  );
};
