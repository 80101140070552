import 'isomorphic-fetch';

import { HttpMethod } from '@entities/enums';

export class HttpErrorResponse extends Response {
  data: any;

  constructor(data: any, body?: BodyInit, init?: ResponseInit) {
    super(body, init);

    this.data = data;
  }
}

export type RequestParams<K extends object> = {
  endpoint: string;
  useCredentials: boolean;
  body?: K;
  headers?: object;
};

export default class HttpService {
  static get<T>(params: Omit<RequestParams<object>, 'body'>): Promise<T> {
    return this.request(HttpMethod.Get, params);
  }

  static post<T, K extends object>(params: RequestParams<K>): Promise<T> {
    return this.request(HttpMethod.Post, params);
  }

  static put<T, K extends object>(params: RequestParams<K>): Promise<T> {
    return this.request(HttpMethod.Put, params);
  }

  private static request<T>(
    method: HttpMethod,
    { endpoint, useCredentials, body, headers }: RequestParams<object>
  ): Promise<T> {
    let request: RequestInit = {
      credentials: useCredentials ? 'include' : 'omit',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
      },
      method,
    };

    if (body) {
      request = { ...request, body: JSON.stringify(body) };
    }

    return fetch(endpoint, request).then(async (response) => {
      const contentType = response.headers.get('content-type');
      const json = contentType
        ? contentType.indexOf('application/json') !== -1 ||
          contentType.indexOf('application/problem+json') !== -1
        : false;
      //the OHS signups API returns text with a content type of application/json
      //this is a workaround to handle that
      let data;
      try {
        data = json ? await response.clone().json() : await response.clone().text();
      } catch (e) {
        data = await response.clone().text();
      }

      if (!response.ok) {
        const error = new HttpErrorResponse(data, response.body || undefined, {
          headers: response.headers,
          status: response.status,
          statusText: response.statusText,
        });

        return Promise.reject(error);
      }

      return Promise.resolve(data);
    });
  }
}
