import '@ovotech/element/reset.css';
import '@ovotech/element/theme.css';
import './index.css';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme, theme as ovoTheme } from '@ovotech/element';

import IconProvider from '@resources/icons/IconProvider';

export type OVOTheme = typeof ovoTheme;

type themeOverrides = {
  /**
   * @deprecated This object should not be used, its only purpose is to map the v4 theme colours to the existing Nebula theme tokens
   * Please use the actual theme tokens now https://design.ovo.com/latest/foundations/colour/overview-9rF1ox0j
   */
  colors: {
    //Maps to theme.core.color.brand.ovo
    primary: string;
    // Maps to theme.core.color.brand.forest
    primaryAlt: string;
    // Maps to theme.core.color.brand.offwhite
    primaryMuted: string;
    // Maps to theme.core.color.brand.white
    primaryContrast: string;
    error: string;
    errorAlt: string;
    errorContrast: string;
    errorMuted: string;
    warning: string;
    warningAlt: string;
    warningContrast: string;
    warningMuted: string;
    info: string;
    infoAlt: string;
    infoContrast: string;
    infoMuted: string;
    success: string;
    successAlt: string;
    successContrast: string;
    successMuted: string;
    focus: string;
    body: string;
    heading: string;
    border: string;
    borderMuted: string;
    canvas: string;
    canvasMuted: string;
    brand: {
      midnight: string;
      forest: string;
      ovo: string;
      leaf: string;
      energised: string;
      sand: string;
      white: string;
      black: string;
      focus: {
        base: string;
      };
      primary: {
        muted: string;
        lightest: string;
        lighter: string;
        light: string;
        base: string;
        dark: string;
        darker: string;
      };
      grey: {
        lightest: string;
        lighter: string;
        light: string;
        base: string;
        dark: string;
        darker: string;
        darkest: string;
      };
      mission: {
        base: string;
        dark: string;
      };
      complementary: {
        base: string;
      };
      electricity: {
        base: string;
        dark: string;
      };
      gas: {
        base: string;
        dark: string;
      };
      error: {
        lightest: string;
        base: string;
        dark: string;
      };
    };
  };
  /**
   * @deprecated This object should not be used,
   * Moved to theme.core.space
   *
   */
  space: typeof theme.core.space;
  /**
   * @deprecated This object should not be used,
   * removed in v4
   *
   */
  fontSizes: string[];
  /**
   * @deprecated This object should not be used,
   * moved to theme.core.fontsizes
   *
   */
  responsiveFontSizes: {
    small: string[];
    body: string[];
    level1: string[];
    level2: string[];
    level3: string[];
    level4: string[];
  };
};

type CompleteTheme = OVOTheme & themeOverrides;

declare module 'styled-components' {
  export interface DefaultTheme extends CompleteTheme {
    [key: string]: any;
  }
}
/*
 we are not adding customer colours, just mapping colours from the v4 theme back to the tokens in the existing Nebula theme. This is purely to aid in migration
 */
const completeTheme: CompleteTheme = {
  ...ovoTheme,
  colors: {
    primary: theme.core.color.brand.midnight,
    primaryAlt: theme.core.color.brand.black,
    primaryContrast: theme.core.color.brand.white,
    primaryMuted: theme.core.color.brand.offwhite,
    error: theme.semantic.error.message,
    errorAlt: theme.semantic.error.surfaceEmphasis,
    errorContrast: theme.semantic.error.messageOnEmphasis,
    errorMuted: theme.semantic.error.surface,
    warning: theme.semantic.warning.message,
    warningAlt: theme.semantic.warning.surfaceEmphasis,
    warningContrast: theme.semantic.warning.messageOnEmphasis,
    warningMuted: theme.semantic.warning.surface,
    info: theme.semantic.info.message,
    infoAlt: theme.semantic.info.surfaceEmphasis,
    infoContrast: theme.semantic.info.messageOnEmphasis,
    infoMuted: theme.semantic.info.surface,
    success: theme.semantic.success.message,
    successAlt: theme.semantic.success.surfaceEmphasis,
    successContrast: theme.semantic.success.messageOnEmphasis,
    successMuted: theme.semantic.success.surface,
    focus: theme.semantic.focus.outline,
    body: theme.semantic.message.base,
    heading: theme.semantic.branded.heading,
    border: theme.semantic.border.functional,
    borderMuted: theme.semantic.border.differentiated,
    canvas: theme.semantic.surface.elevated,
    canvasMuted: theme.semantic.surface.base,
    brand: {
      midnight: theme.core.color.brand.midnight,
      forest: theme.core.color.brand.forest,
      ovo: theme.core.color.brand.ovo,
      leaf: theme.core.color.brand.leaf,
      energised: theme.core.color.brand.energised,
      sand: theme.core.color.brand.offwhite,
      white: theme.core.color.brand.white,
      black: theme.core.color.brand.black,
      focus: {
        base: theme.core.color.blue.base,
      },
      primary: {
        muted: theme.core.color.brand.energisedTint,
        lightest: theme.core.color.brand.white,
        lighter: theme.core.color.brand.energised,
        light: theme.core.color.brand.leaf,
        base: theme.core.color.brand.ovo,
        dark: theme.core.color.brand.forest,
        darker: theme.core.color.brand.midnight,
      },
      grey: {
        lightest: theme.core.color.brand.white,
        lighter: theme.core.color.neutral.lightest,
        light: '#F3F3F3',
        base: theme.core.color.neutral.lighter,
        dark: theme.core.color.brand.forest,
        darker: theme.core.color.brand.midnight,
        darkest: theme.core.color.brand.midnight,
      },
      mission: {
        base: theme.core.color.brand.midnight,
        dark: theme.core.color.brand.black,
      },
      complementary: {
        base: theme.core.color.brand.forest,
      },
      electricity: {
        base: theme.core.color.yellow.darker,
        dark: theme.core.color.yellow.darkest,
      },
      gas: {
        base: theme.core.color.blue.base,
        dark: theme.semantic.data.gas['3'],
      },
      error: {
        lightest: theme.core.color.red.lightest,
        base: theme.core.color.red.darker,
        dark: theme.core.color.red.darkest,
      },
    },
  },
  space: theme.core.space,
  fontSizes: ['14', '16', '20', '24', '28', '32', '36', '48'],
  responsiveFontSizes: {
    small: [theme.core.fontSize.small.small, theme.core.fontSize.small.large],
    body: [theme.core.fontSize.body.small, theme.core.fontSize.body.large],
    level1: [theme.core.fontSize.heading1.small, theme.core.fontSize.heading1.large],
    level2: [theme.core.fontSize.heading2.small, theme.core.fontSize.heading2.large],
    level3: [theme.core.fontSize.heading3.small, theme.core.fontSize.heading3.large],
    level4: [theme.core.fontSize.heading4.small, theme.core.fontSize.heading4.large],
  },
};

export const AppThemeProvider: React.FC = ({ children }) => (
  <ThemeProvider theme={completeTheme}>
    <IconProvider>{children}</IconProvider>
  </ThemeProvider>
);
