import React, { createContext, ReactNode, useContext, useState } from 'react';

export interface Footnote {
  id: string;
  content: ReactNode;
}

export interface FootnoteReference extends Footnote {
  index: number;
  supId: string;
  linkId: string;
}

interface FootnoteContext {
  referenced: FootnoteReference[];
  common: Footnote[];
  setReferenced?: React.Dispatch<React.SetStateAction<Array<FootnoteReference>>>;
  setCommon?: React.Dispatch<React.SetStateAction<Array<Footnote>>>;
}

const defaultContext: FootnoteContext = {
  referenced: [],
  common: [],
};

const Context = createContext(defaultContext);

export const FootnotesProvider: React.FC = ({ children }) => {
  const [referenced, setReferenced] = useState<Array<FootnoteReference>>([]);
  const [common, setCommon] = useState<Array<Footnote>>([]);

  const footnotesContextValue = {
    referenced,
    common,
    setReferenced,
    setCommon,
  };

  return <Context.Provider value={footnotesContextValue}>{children}</Context.Provider>;
};

export const useFootnotes = () => {
  const context = useContext(Context);

  if (context !== undefined) {
    return context;
  }

  throw new Error('useFootnotes must be used within a FootnoteProvider');
};
