import { cloneDeep } from 'lodash';

import StoreService from './StoreService';

import { ProductName, Promo } from '@entities/enums';
import { isBrowser } from '@utils/isBrowser';
import { getDirectPromos, getIndirectPromos } from '@fixtures/PromoConfigs';
import { PromoName } from '@entities/types';
import { getCurrentDate } from '@utils/DateUtils';

type OveridePromo = {
  name: PromoName;
  enable: boolean;
};

class PromoService {
  private promo?: Promo | undefined;

  promoExistsInStore(promoCode: Promo) {
    const promoCodeFromStore = StoreService.load().promoCode;
    return promoCodeFromStore === promoCode;
  }

  getValidPromoCodes(productsExcluded?: ProductName[]) {
    const allDirectPromoConfigs = cloneDeep(getDirectPromos());
    const currentDate = getCurrentDate();
    return allDirectPromoConfigs.reduce((acc: Promo[], promoConfig) => {
      if (promoConfig.appliesTo.length === 0) {
        return acc;
      }

      if (!promoConfig?.promoOptions?.codes?.length) {
        return acc;
      }

      const appliesTo = !!promoConfig.appliesTo.filter(
        (applyTo) => !productsExcluded?.includes(applyTo)
      ).length;

      if (!appliesTo) {
        return acc;
      }

      const codes = [...promoConfig.promoOptions.codes];
      const overridePromos = this.getOverridePromos();

      if (overridePromos) {
        const overridePromo = overridePromos.find(
          (overridePromo) => overridePromo.name === promoConfig.name
        );

        if (overridePromo?.enable) {
          return [...acc, ...(codes as Promo[])];
        }

        return acc;
      }

      if (promoConfig.startDate && promoConfig.startDate > currentDate) {
        return acc;
      }

      if (promoConfig.endDate && promoConfig.endDate < currentDate) {
        return acc;
      }

      if (!(promoConfig?.validate?.() ?? true)) {
        return acc;
      }

      return [...acc, ...(codes as Promo[])];
    }, []);
  }

  getActiveDirectPromos() {
    const store = StoreService.load();

    const activePromoCode = store.promoCode;
    const allDirectPromoConfigs = cloneDeep(getDirectPromos());
    const currentDate = getCurrentDate();
    const overridePromos = this.getOverridePromos();

    return allDirectPromoConfigs.filter((promoConfig) => {
      if (promoConfig.appliesTo.length === 0) {
        return false;
      }

      if (!promoConfig?.promoOptions?.codes?.length) {
        return false;
      }

      const codes = [...promoConfig.promoOptions.codes];

      if (!codes.includes(activePromoCode)) {
        return false;
      }

      if (overridePromos) {
        const overridePromo = overridePromos.find(
          (overridePromo) => overridePromo.name === promoConfig.name
        );

        if (overridePromo) {
          return (overridePromo?.enable && promoConfig?.validate?.()) ?? true;
        }
      }

      if (promoConfig.startDate && promoConfig.startDate > currentDate) {
        return false;
      }

      if (promoConfig.endDate && promoConfig.endDate < currentDate) {
        return false;
      }

      return promoConfig?.validate?.() ?? true;
    });
  }

  getActiveIndirectPromos() {
    const currentDate = getCurrentDate();
    const promos = cloneDeep(getIndirectPromos());
    const overridePromos = this.getOverridePromos();

    return promos.filter((promo) => {
      if (promo.appliesTo.length === 0) {
        return false;
      }

      if (overridePromos) {
        const overridePromo = overridePromos.find(
          (overridePromo) => overridePromo.name === promo.name
        );

        if (overridePromo) {
          return (overridePromo?.enable && promo?.validate?.()) ?? true;
        }
      }

      if (promo.startDate && promo.startDate > currentDate) {
        return false;
      }

      if (promo.endDate && promo.endDate < currentDate) {
        return false;
      }

      return promo?.validate?.() ?? true;
    });
  }

  getPromoCodesFromUrl() {
    return isBrowser() ? new URLSearchParams(location.search).getAll('promoCode') : [];
  }

  handlePromoFromUrl() {
    const promoCodesFromUrl = this.getPromoCodesFromUrl();
    if (promoCodesFromUrl.length) {
      const inputPromoCode = promoCodesFromUrl[0] as Promo;
      const isValidPromo = this.getValidPromoCodes().includes(inputPromoCode);
      if (!isValidPromo) return;
      this.setPromo(inputPromoCode);
      this.savePromoToStore(inputPromoCode);
    }
  }

  /**
   * Does the current URL contain any of the promoCode arguments? Unlike
   * `promoExistsInUrlOrStore` this function will not save the promo code
   * to the StoreService.
   */
  promoExistsInUrl(promoCode: Promo | Promo[]): boolean {
    const promoCodesInUrl = this.getPromoCodesFromUrl();

    return Array.isArray(promoCode)
      ? promoCode.some((promo) => promoCodesInUrl.includes(promo as string))
      : promoCodesInUrl.includes(promoCode);
  }

  /**
   * Does the promo code exist in the URL, or in the StoreService? If the promo
   * code is found in the URL it will be saved into the StoreService
   */
  promoExistsInUrlOrStore(promoCode: Promo): boolean {
    if (this.promoExistsInUrl(promoCode)) {
      this.savePromoToStore(promoCode);
    }

    // See if promos are defined in store
    return this.promoExistsInStore(promoCode);
  }

  setPromo(promo: Promo) {
    this.promo = promo;
  }

  savePromoToStore(promo: Promo) {
    this.setPromo(promo);
    StoreService.save({ promoCode: promo });
  }

  getAppliedPromoCode(): Promo | undefined {
    const { promoCode } = StoreService.load();

    if (!promoCode) return undefined;

    const appliedPromo = promoCode as Promo;
    return appliedPromo;
  }

  getPromo(): Promo | undefined {
    if (this.promo) {
      return this.promo;
    }

    const promoFromStore = StoreService.load()?.promoCode as Promo;

    if (!promoFromStore) {
      return undefined;
    }

    return promoFromStore;
  }

  clearPromoFromUrl(): void {
    const promoCodesFromUrl = this.getPromoCodesFromUrl();
    if (isBrowser() && promoCodesFromUrl.length) {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);

      params.delete('promoCode');

      const urlWithoutPromo = `${url.pathname}${params.toString() ? `?${params.toString()}` : ''}`;
      history.replaceState(null, '', urlWithoutPromo);
    }
  }

  clear(): void {
    this.clearPromoFromUrl();
    StoreService.save({ promoCode: '' });
    this.promo = undefined;
  }

  private getOverridePromos() {
    const environment = process.env.GATSBY_ACTIVE_ENV;

    if (environment === 'production') {
      return null;
    }

    const overridePromosFromBrowserStore =
      isBrowser() && window.localStorage.getItem('overridePromos');

    const overridePromos: OveridePromo[] =
      overridePromosFromBrowserStore && JSON.parse(overridePromosFromBrowserStore);

    return overridePromos;
  }
}

export default new PromoService();
