import { FC } from 'react';
import { Calendar, dateFnsLocalizer, Event } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enGB from 'date-fns/locale/en-GB';
import { endOfYear, startOfYear } from 'date-fns';

import { getDirectPromos, getIndirectPromos } from '@fixtures/PromoConfigs';
import { PromoConfig } from '@entities/types';

import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';

interface CustomEvent extends Event {
  isDirect: boolean;
  codes?: string[];
  title: string;
}

const locales = {
  'en-GB': enGB,
};

const localise = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const defaultEndDate = endOfYear(new Date());
const defaultStartDate = startOfYear(new Date());

const sortPromoByOrder = (promoA: PromoConfig, promoB: PromoConfig) => promoA.order - promoB.order;

export const OffersCalendar: FC = () => {
  const directPromos = getDirectPromos().sort(sortPromoByOrder);
  const indirectPromos = getIndirectPromos().sort(sortPromoByOrder);

  const directPromoEvents = directPromos.map(({ startDate, endDate, name, promoOptions }) => ({
    title: name,
    start: startDate ? new Date(startDate) : defaultStartDate,
    end: endDate ? new Date(endDate) : defaultEndDate,
    isDirect: true,
    codes: promoOptions?.codes,
  }));

  const indirectPromoEvents = indirectPromos.map(({ startDate, endDate, name }) => ({
    title: name,
    start: startDate ? new Date(startDate) : defaultStartDate,
    end: endDate ? new Date(endDate) : defaultEndDate,
    isDirect: false,
  }));

  const allEvents: CustomEvent[] = [...directPromoEvents, ...indirectPromoEvents];

  return (
    <Calendar
      defaultView="month"
      events={allEvents}
      localizer={localise}
      style={{ height: '100vh' }}
      eventPropGetter={(event) => {
        return {
          style: {
            backgroundColor: event.isDirect ? 'lightblue' : 'lightgreen',
          },
        };
      }}
      toolbar={true}
      tooltipAccessor={(event) => {
        if (event.codes) {
          return `Name: ${event.title}\nCodes: ${event.codes.join(', ')}`;
        }
        return `Name: ${event.title}`;
      }}
    />
  );
};
