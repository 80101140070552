import { noop } from 'lodash';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { AccountId } from '@entities/types';

const STORAGE_KEY = 'homePlan:store';

interface LocalStore {
  signedUpAccounts: AccountId[];
  optimizelyUserId?: string;
  testUser?: boolean;
}

interface ContextValue {
  load(): LocalStore;
  save(partial: Partial<LocalStore>): void;
  clear(): void;
}

const defaultStore: LocalStore = {
  signedUpAccounts: [],
  testUser: false,
};

const defaultValue: ContextValue = {
  save: noop,
  load: () => defaultStore,
  clear: noop,
};

const LocalStorageContext = createContext(defaultValue);

export const LocalStorageProvider: React.FC = ({ children }) => {
  const storeRef = useRef(isBrowser() ? getStoreFromBrowser() : defaultStore);

  useEffect(() => {
    const store = getStoreFromBrowser();
    storeRef.current = store;
  }, []);

  const value: ContextValue = useMemo(
    () => ({
      load: () => storeRef.current,
      save: (partial: Partial<LocalStore>) => {
        if (isBrowser()) {
          const newStore = {
            ...storeRef.current,
            ...partial,
          };

          storeRef.current = newStore;
          storeInBrowser(newStore);
        }
      },
      clear: () => {
        if (isBrowser()) {
          window.localStorage.removeItem(STORAGE_KEY);
          storeRef.current = defaultStore;
        }
      },
    }),
    []
  );

  return (
    <LocalStorageContext.Provider value={value}>
      {children}
    </LocalStorageContext.Provider>
  );
};

const isBrowser = () => typeof window !== 'undefined';

const getStoreFromBrowser = (): LocalStore => {
  if (!isBrowser()) {
    return defaultStore;
  }

  const storeFromBrowser = window.localStorage.getItem(STORAGE_KEY);
  return storeFromBrowser ? JSON.parse(storeFromBrowser) : defaultStore;
};

const storeInBrowser = (store: LocalStore) => {
  if (isBrowser()) {
    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(store));
  }
};

export const useLocalStorage = () => useContext(LocalStorageContext);

export const defaultLocalStore = defaultStore;
